import { AnimatePresence, LazyMotion, m } from "framer-motion";
import { useEffect, useState } from "react";
const loadFeatures = () =>
  import("utilities/framerFeatures.js").then((res) => res.default);

export interface FilterAccordionProps {
  className?: string;
  filter: any;
  i: number;
  open?: boolean;
  children?: React.ReactNode;
  onToggle?: (i: number) => void;
  isMobile?: boolean;
  isItemExpanded?: boolean;
  setIsItemExpanded?: any;
  selectedFilters: any;
}

const FilterAccordion = ({
  className,
  filter,
  i,
  open,
  children,
  onToggle,
  isItemExpanded,
  setIsItemExpanded,
  selectedFilters,
}: FilterAccordionProps) => {
  const [expanded, setExpanded] = useState(open);

  const handleToggle = () => {
    setExpanded(!expanded);
    if (onToggle) {
      onToggle(i);
    }
    setIsItemExpanded(!isItemExpanded);
  };

  useEffect(() => {
    if (!isItemExpanded) setExpanded(false);
  }, [isItemExpanded]);

  const hasFilters = selectedFilters[filter.filterSlug].length > 0;
  const activeFilters = selectedFilters[filter.filterSlug];

  return (
    <LazyMotion features={loadFeatures}>
      <m.li
        className={`${className} flex max-h-[400px] w-full cursor-pointer flex-col overflow-scroll px-3 py-2 transition-all duration-500 ease-in-out lg:px-0`}
      >
        {(filter.name === "Primary Breed" || filter.name === "Attributes") && (
          <m.header className={``}>
            <h2 className={`mb-2 text-center font-semibold uppercase`}>
              {filter.name === "Primary Breed" ? "Breed" : filter.name}
            </h2>
            <button
              type="button"
              className={`flex w-full items-center justify-between rounded-md bg-gray-100 px-4 py-2 font-body text-2xl`}
              onClick={handleToggle}
            >
              <h2 className={`max-w-[90%] text-lg text-dark md:text-2xl`}>
                {hasFilters ? `${activeFilters.join(", ")}` : 'Any'}
              </h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 24 24"
              >
                <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
              </svg>
            </button>
          </m.header>
        )}
        {filter.name !== "Primary Breed" && filter.name !== "Attributes" && (
          <m.header className={``}>
            <h2 className={`mb-2 text-center font-semibold uppercase`}>
              {filter.name}
            </h2>
          </m.header>
        )}
        {(filter.name === "Primary Breed" || filter.name === "Attributes") && (
          <AnimatePresence initial={false}>
            {expanded && (
              <m.section
                className={`absolute bottom-0 left-0 top-[70px] h-[calc(100%-126px)] z-40 w-full overflow-scroll bg-white px-5 py-4 font-body text-lg text-gray-800`}
                key="content"
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { opacity: 1, visibility: "visible" },
                  collapsed: { opacity: 0, visibility: "hidden" },
                }}
                transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
              >
                {children}
              </m.section>
            )}
          </AnimatePresence>
        )}
        {filter.name !== "Primary Breed" && filter.name !== "Attributes" && (
          <div className={`mb-5 grid grid-cols-2 gap-2`}>{children}</div>
        )}
      </m.li>
    </LazyMotion>
  );
};

export default FilterAccordion;
